import Rails from "@rails/ujs"
import jquery from "jquery"
import lodash from "lodash"
window.$ = window.jQuery = jquery
window._ = lodash
Rails.start()
window.Rails = Rails


$(function () {
  // toggle password field type
  $('.password-eye').on('click', function () {
    const $control = $(this.previousElementSibling)
    $control.attr('type') === 'text' ? $control.attr('type', 'password')
                                     : $control.attr('type', 'text')
  })

  // password conditions check
  const $passwordConditions = $('.password-conditions')
  if ($passwordConditions.length > 0) {
    const numReg = /[0-9]/
    const lowerReg = /[a-z]/
    const upperReg = /[A-Z]/
    const digitReg = /^.{8,}$/
    const symbolReg = /[!"#$%&'()*+\-.,\/:;<=>?@\[\\\]^_`{|}~]/

    const $password = $($passwordConditions.attr('for'))
    const $charNum = $passwordConditions.find('.char-num')
    const $upperChar = $passwordConditions.find(".upper-char")
    const $symbol = $passwordConditions.find((".symbol"))
    const $digit = $passwordConditions.find(".digit")
    $password.on('keyup', function () {
      (numReg.test(this.value) && lowerReg.test(this.value)) ? $charNum.addClass('ok') : $charNum.removeClass('ok')
      upperReg.test(this.value) ? $upperChar.addClass('ok') : $upperChar.removeClass('ok')
      symbolReg.test(this.value) ? $symbol.addClass('ok') : $symbol.removeClass('ok')
      digitReg.test(this.value) ? $digit.addClass('ok') : $digit.removeClass('ok')
    })
  }

  $('.post-for-address').on('keyup', function () {
    const postCode = this.value
    if (postCode.length < 7) {
      return
    }
    if (/^[0-9]{7}$/.test(postCode)) {
      axios.get(`https://madefor.github.io/postal-code-api/api/v1/${postCode.slice(0, 3)}/${postCode.slice(3, 7)}.json`)
        .then((response) => {
          const data = response.data.data[0]
          const $wrapper = $(this.getAttribute('for'))
          const $pref = $wrapper.find(".pref")
          const $address1 = $wrapper.find(".address1")
          const $address2 = $wrapper.find(".address2")
          const $address3 = $wrapper.find(".address3")
          $wrapper.addClass('show')
          $pref.val(data.ja.prefecture).change()
          $address1.val(data.ja.address1).change()
          $address2.val(data.ja.address2).change()
          $address3.val(data.ja.address3 + data.ja.address4).change()
        })
        .catch((err) => {
          alert("申し訳ありません。該当する住所が見つからないようです。")
        })
    } else {
      alert('申し訳ありません。数字7桁で入力してください。')
    }
  })

  //ticker
  let timerId
  const hideTicker = ($elem) => {
    if (timerId) {
      clearTimeout(timerId)
      timerId = undefined
    }
    $elem.removeClass('show').addClass('hide')
    setTimeout(() => { $elem.removeClass('hide') }, 1000)
    $elem.find('p').text('')
  }
  const trapFlash = ($elem) => {
    if ($elem.find('p').text() !== '') {
      $elem.addClass('show')
      setTimeout(() => { hideTicker($elem) }, 5000)
    }
    $elem.find('.close').on('click', function () {
      hideTicker($elem)
    })
  }
  trapFlash($('#flash-alert'))
  trapFlash($('#flash-notice'))
})
